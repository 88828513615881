import * as React from "react"

// UI
import Section from "~components/Section"
import Headline from "~ui/Headline"

// Media
import EnerveeLogo from "~images/customers/enervee/enervee-logo-dark.svg"
import FlexportLogo from "~images/customers/flexport/flexport-logo-dark.svg"
import NalupayLogo from "~images/customers/nalupay/nalupay-logo-dark.png"
import NovoLogo from "~images/customers/novo/novo-logo-dark.svg"
import TandymLogo from "~images/customers/tandym/tandym-logo-dark.svg"

interface SectionFiveColsProps {
  children: React.ReactNode
  className?: string
  title?: string | React.ReactNode
  body?: string
}

const SectionFiveCols: React.FC<SectionFiveColsProps> = ({
  className,
  title,
  body,
  children,
}: SectionFiveColsProps) => {
  const SectionFiveColsBaseClasses = "mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-0"

  return (
    <div className={`${SectionFiveColsBaseClasses} ${className}`}>
      <div>
        {title && (
          <Headline level={2} data-sal="slide-up">
            {title}
          </Headline>
        )}
        {body && (
          <p className="lg:text-lg md:mb-6 max-w-4xl 2xl:max-w-5xl" data-sal="slide-up" data-sal-delay="100">
            {body}
          </p>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-6 pt-8 md:pt-12">{children}</div>
    </div>
  )
}

const FourColCard = (props) => (
  <div
    className="rounded-xl bg-gray-100 dark:bg-gray-900 p-6 md:p-4 lg:p-6 2xl:p-8 flex justify-center items-center h-20 sm:h-20 md:h-12 lg:h-20 2xl:h-24"
    data-sal="slide-up"
    data-sal-delay={props.salDelay}
  >
    <img
      src={props.logo}
      alt={props.name}
      className={`w-32 filter dark:invert ${props.className ? props.className : ""}`}
    />
  </div>
)

const CustomersList = (): JSX.Element => {
  return (
    <Section>
      <SectionFiveCols title="Trusted by Modern Lending Innovators" className="grid-3 mb-20 md:mb-24 lg:mb-32">
        <FourColCard name="Novo" logo={NovoLogo} salDelay="0" />
        <FourColCard name="Flexport" logo={FlexportLogo} salDelay="100" />
        <FourColCard name="Tandym" logo={TandymLogo} salDelay="200" />
        <FourColCard name="NaluPay" logo={NalupayLogo} salDelay="300" />
        <FourColCard name="Enervee" logo={EnerveeLogo} salDelay="400" />
      </SectionFiveCols>
    </Section>
  )
}

export default CustomersList
