import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import CustomersIndexHero from "~content/customers/index/Hero"
import CustomersList from "~content/customers/index/CustomersList"
import CaseStudies from "~content/customers/index/CaseStudies"
import CaseStudiesMedia from "~content/customers/index/Media"

const CustomersIndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/customers/"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="Customers | Canopy Servicing"
      />
      <CustomersIndexHero />
      <CustomersList />
      <CaseStudies />
      <CaseStudiesMedia />
    </Layout>
  )
}

export default CustomersIndexPage
