import * as React from "react"

// UI
import SectionAlt from "~components/SectionAlt"
import SectionThreeCols from "~components/SectionThreeCols"
import CaseStudyCard from "./CaseStudyCard"

// Media
import NalupayImage from "~images/customers/nalupay/nalupay-index.jpg"
import NalupayLogo from "~images/customers/nalupay/nalupay-logo.png"
import NovoImage from "~images/customers/novo/novo-index.jpg"
import NovoLogo from "~images/customers/novo/novo-logo.svg"
import TandymImage from "~images/customers/tandym/tandym-index.jpg"
import TandymLogo from "~images/customers/tandym/tandym-logo.svg"

const CareersBenefits = (): JSX.Element => {
  return (
    <SectionAlt color="dark" className="mb-16 md:mb-20 lg:mb-32 text-gray-300">
      <SectionThreeCols
        title="Customer Case Studies"
        titleTextSize="text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-yellow-300"
        body="Explore how fintech companies like Novo, Tandym, and NaluPay have been able to focus on their long-term vision by partnering with Canopy to power their lending core."
      >
        <CaseStudyCard
          clientName="Novo"
          imgSrc={NovoImage}
          logoSrc={NovoLogo}
          tagPrimary="MCA"
          tagSecondary="Commercial"
          to="/customers/novo"
          data-sal="slide-up"
        />
        <CaseStudyCard
          clientName="Tandym"
          imgSrc={TandymImage}
          logoSrc={TandymLogo}
          tagPrimary="Revolving"
          tagSecondary="Commercial"
          to="/customers/tandym"
          data-sal="slide-up"
          data-sal-delay="100"
        />
        <CaseStudyCard
          clientName="NaluPay"
          imgSrc={NalupayImage}
          logoSrc={NalupayLogo}
          tagPrimary="POS Financing"
          tagSecondary="Consumer"
          to="/customers/nalupay"
          data-sal="slide-up"
          data-sal-delay="200"
        />
      </SectionThreeCols>
    </SectionAlt>
  )
}

export default CareersBenefits
