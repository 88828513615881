import React from "react"

// UI
import Button from "~ui/Button"

const TagItem = (props) => (
  <span
    className={`rounded-full border border-white px-2 xl:px-3 py-1.5 xl:py-2 text-xs font-semibold mr-1 ${
      props.secondary && "border-opacity-30"
    }`}
  >
    {props.children}
  </span>
)

const CaseStudyCard: React.FC<CaseStudyCardProps> = ({
  clientName,
  imgSrc,
  tagPrimary,
  tagSecondary,
  logoSrc,
  to,
  ...props
}: CaseStudyCardProps) => (
  <div
    className="rounded-3xl overflow-hidden h-64 lg:h-96 w-full mx-1 lg:mx-2 bg-gray-800 bg-cover bg-center"
    style={{ backgroundImage: `url(${imgSrc})` }}
    {...props}
  >
    <div className="flex flex-col justify-between h-full p-6 xl:p-8 text-white">
      <div>
        <TagItem>{tagPrimary}</TagItem>
        <TagItem secondary>{tagSecondary}</TagItem>
      </div>
      <div>
        <img src={logoSrc} alt={`${clientName} Logo`} width="140" />
        {to ? (
          <Button to={to} className="mt-6">
            View Case Study
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
)

interface CaseStudyCardProps {
  clientName: string
  imgSrc: string
  tagPrimary: string
  tagSecondary: string
  logoSrc: string
  to?: string
}

export default CaseStudyCard
