import React from "react"
import Marquee from "react-fast-marquee"

// UI
import SectionHero from "~components/SectionHero"
import CaseStudyCard from "./CaseStudyCard"

// Media
import FlexportImage from "~images/customers/flexport/flexport-index.jpg"
import FlexportLogo from "~images/customers/flexport/flexport-logo.svg"
import NalupayImage from "~images/customers/nalupay/nalupay-index.jpg"
import NalupayLogo from "~images/customers/nalupay/nalupay-logo.png"
import NovoImage from "~images/customers/novo/novo-index.jpg"
import NovoLogo from "~images/customers/novo/novo-logo.svg"
import TandymImage from "~images/customers/tandym/tandym-index.jpg"
import TandymLogo from "~images/customers/tandym/tandym-logo.svg"

const CustomersIndexHero: React.FC = () => (
  <>
    <SectionHero
      title="Hear From Our Customers"
      subtitle="From building a multi-credit program for thousands of small businesses, to launching a wellness-based credit card and installment product for e-commerce merchants, our customers are never limited in the embedded finance products they aspire to build."
      level={1}
      buttons={false}
    />
    <Marquee gradientWidth={120} pauseOnHover={true} className="mt-8 lg:mt-0 mb-20 lg:mb-32">
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="NaluPay"
          imgSrc={NalupayImage}
          logoSrc={NalupayLogo}
          tagPrimary="POS Financing"
          tagSecondary="Consumer"
          to="/customers/nalupay"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Novo"
          imgSrc={NovoImage}
          logoSrc={NovoLogo}
          tagPrimary="MCA"
          tagSecondary="Commercial"
          to="/customers/novo"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Flexport"
          imgSrc={FlexportImage}
          logoSrc={FlexportLogo}
          tagPrimary="Invoice Factoring"
          tagSecondary="Commercial"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Tandym"
          imgSrc={TandymImage}
          logoSrc={TandymLogo}
          tagPrimary="Revolving"
          tagSecondary="Commercial"
          to="/customers/tandym"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="NaluPay"
          imgSrc={NalupayImage}
          logoSrc={NalupayLogo}
          tagPrimary="POS Financing"
          tagSecondary="Consumer"
          to="/customers/nalupay"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Novo"
          imgSrc={NovoImage}
          logoSrc={NovoLogo}
          tagPrimary="MCA"
          tagSecondary="Commercial"
          to="/customers/novo"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Flexport"
          imgSrc={FlexportImage}
          logoSrc={FlexportLogo}
          tagPrimary="Invoice Factoring"
          tagSecondary="Commercial"
        />
      </div>
      <div className="mx-2 w-72 md:w-96">
        <CaseStudyCard
          clientName="Tandym"
          imgSrc={TandymImage}
          logoSrc={TandymLogo}
          tagPrimary="Revolving"
          tagSecondary="Commercial"
          to="/customers/tandym"
        />
      </div>
    </Marquee>
  </>
)

export default CustomersIndexHero
