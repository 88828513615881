import * as React from "react"

// UI
import SectionMediaFull from "~components/SectionMediaFull"

const CaseStudiesMedia = (): JSX.Element => {
  return (
    <SectionMediaFull
      title="Should You Buy or Build an LMS?"
      subTitle="Choosing whether to partner with a loan servicing platform provider or build your system in-house can be a major decision for bootstrapped companies. Hear from one client on why they chose to partner with Canopy rather than invest in a dedicated internal team."
      video={
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/zhpEJx6tuK0?si=SKgcW-cly_8BHHLu?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      }
      className="2xl:mb-20"
    />
  )
}

export default CaseStudiesMedia
